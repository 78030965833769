import { ILoadingOverlayParams } from "ag-grid-community";
import { useEffect } from "react";

interface OwnProps {
  projectDirectoriesNewButton: JSX.Element;
}

type Props = OwnProps & Partial<ILoadingOverlayParams>;

export const TableNoRowsOverlay = ({ projectDirectoriesNewButton, api }: Props) => {
  useEffect(() => {
    const loadingOverlay = document.querySelector(".ag-overlay-loading-wrapper");
    if (loadingOverlay) {
      // This seems to be necessary, otherwise we get stuck on the loading overlay
      // Possibly related: https://github.com/ag-grid/ag-grid/issues/7028
      console.log("Calling showNoRowsOverlay again as loading overlay is still present");
      api?.showNoRowsOverlay();
    }
  }, [api]);

  return (
    <div className="pointer-events-auto flex h-full min-h-[300px] w-full flex-col items-center gap-24 pt-120">
      <div className="text-14-20 text-gray-700">This directory is empty</div>
      {projectDirectoriesNewButton}
    </div>
  );
};
