import KeyboardShortcut from "@components/library/KeyboardShortcut";
import Tooltip from "@components/library/Tooltip";
import { ICellRendererParams } from "ag-grid-community";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";

export interface CheckboxCellRendererParams {
  /**
   * The field name to use for the id.
   * @default "id"
   */
  idDataKey?: string;
  setCheckedIds: Dispatch<SetStateAction<string[]>>;
  checkedIds: string[];

  /**
   * Optional callback to determine if checkbox should be hidden for a row.
   * @param data The row data
   * @returns true if checkbox should be hidden, false otherwise
   */
  hideCheckbox?: (data: any) => boolean;
}

const CheckboxCellRenderer = <T extends { id: string; [key: string]: any }>({
  data,
  colDef: {
    cellRendererParams: { setCheckedIds, checkedIds, idDataKey = "id", hideCheckbox },
  },
}: ICellRendererParams<T> & {
  colDef: { cellRendererParams: CheckboxCellRendererParams };
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const id = data?.[idDataKey];
  const toggleChecked = useCallback(
    (event: CheckboxChangeEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!id) return;

      setCheckedIds((prevIds: string[] | null) => {
        if (!prevIds) return [id];
        const isAlreadyChecked = prevIds.includes(id);
        const newCheckedIds = isAlreadyChecked ? prevIds.filter((_id) => _id !== id) : [...prevIds, id];
        return newCheckedIds;
      });
      inputRef.current?.blur();
    },
    [id, setCheckedIds],
  );

  if (!id) return null;
  if (hideCheckbox && hideCheckbox(data)) return null;

  return (
    <Tooltip
      content={
        <span className="flex items-center gap-6">
          Select <KeyboardShortcut size={16}>x</KeyboardShortcut>
        </span>
      }
    >
      <span>
        <Checkbox
          className="flex w-full justify-center"
          ref={inputRef}
          checked={checkedIds.includes(data[idDataKey])}
          onChange={toggleChecked}
        />
      </span>
    </Tooltip>
  );
};

export default CheckboxCellRenderer;
