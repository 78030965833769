import { classNames } from "@/lib/utils";

interface Props {
  relativePosition?: boolean;
}

export const TableLoadingOverlay = ({ relativePosition }: Props) => {
  return (
    <div className={classNames(relativePosition ? "h-full w-full " : "absolute inset-0", "flex flex-col gap-8 pt-48")}>
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
      <LoadingRow />
    </div>
  );
};

const LoadingRow = () => <div className="h-36 shrink-0 animate-pulse rounded-ms bg-gray-50" />;
