import IconButton from "@components/library/IconButton";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import ActionsMenu, { Action } from "./ActionsMenu";

export const ACTIONS_COL_ID = "actions";

/** This creates the InlineMenu at the end of the row, and also the Cog in the top right */
export const actionsColumnFactory = <T,>(
  actions: Action<T>[],
  headerComponent?: React.ReactNode,
  overrides?: Partial<ColDef<T>>,
): ColDef<T> => {
  return {
    colId: ACTIONS_COL_ID,
    sortable: false,
    resizable: false,
    width: 36,
    maxWidth: 36,
    headerComponent: () => headerComponent ?? null,
    suppressMovable: true,
    cellRenderer: ActionsCellRenderer,
    cellRendererParams: { actions },
    filter: false,
    pinned: "right",
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      padding: 0,
    },
    headerClass(params) {
      return "flex items-center justify-center w-full !px-1";
    },
    ...overrides,
  };
};

type ActionsCellRendererProps<T> = ICellRendererParams<T> & {
  actions: Action<T>[];
};

const ActionsCellRenderer = <T,>(props: ActionsCellRendererProps<T>) => {
  return (
    <div className="flex items-start pt-8">
      <ActionsMenu
        trigger={<IconButton Icon={DotsVerticalIcon} size={24} />}
        actions={props.actions}
        data={props.data}
      />
    </div>
  );
};
