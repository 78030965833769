import { classNames } from "@/lib/utils";
import { EntityIconType, EntityIconSize, EntityIcon } from "@components/library/Entities/EntityIcon";

export const StackedEntityIcons = ({
  entityList,
  size,
  className,
}: {
  entityList: EntityIconType[];
  size: EntityIconSize;
  className?: string;
}) => {
  const sortedEntityList = entityList.toSorted();
  return (
    <div className={classNames("flex", className)}>
      {sortedEntityList.map((entity, index) => (
        <div
          key={index}
          className={classNames("-ml-[3px] rounded-ms bg-white pr-2 ring-2 ring-stroke-base-1 first:ml-0")}
        >
          <EntityIcon key={index} type={entity} size={size} />
        </div>
      ))}
    </div>
  );
};
