import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Button from "@components/library/Button";
import { ArrowRightIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { ContextMenu } from "@components/library/ContextMenu";

interface Props {
  onMove: () => void;
  // onDelete: () => void;
}

export const DirectoriesTableToolbarActions = ({ onMove }: Props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button IconRight={DotsVerticalIcon} styling="solid" shade="blue">
          Actions
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <ContextMenu.Items isDropdownMenu align="end" sideOffset={4} className="min-w-[120px]">
          <ContextMenu.Item withinDropdownMenu onClick={onMove} IconLeft={ArrowRightIcon}>
            Move ...
          </ContextMenu.Item>
          {/* <ContextMenu.Item withinDropdownMenu onClick={onDelete} IconLeft={ArrowRightIcon}>
            Delete
          </ContextMenu.Item> */}
        </ContextMenu.Items>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
