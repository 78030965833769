import { classNames } from "@/lib/utils";
import { Directory } from "@/services/directories.service";
import Button from "@components/library/Button";
import { SearchIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { useState } from "react";
import { DirectoriesTableSearch } from "./DirectoriesTableSearch";
import { DirectoriesTableToolbarActions } from "./DirectoriesTableToolbarActions";

interface Props {
  directory?: Directory;
  checkedIds: string[];
  setCheckedIds: (ids: string[]) => void;
  onMoveAction: () => void;
  // onDeleteAction: () => void;
  searchValue: string;
  handleSearchValueChange: (value: string) => void;
  searchLoading: boolean;
  actionsTray: React.ReactNode;
}

export const DirectoriesTableToolbar = ({
  directory,
  checkedIds,
  setCheckedIds,
  onMoveAction,
  searchValue,
  handleSearchValueChange,
  searchLoading,
  actionsTray,
}: Props) => {
  const [searchOpen, setSearchOpen] = useState(false);
  // When the search is active, it goes over the directories, because search works over all directories.
  return (
    <div>
      <div className="flex h-48 justify-between gap-24 pb-16">
        <div className="flex w-full justify-end gap-16">
          <motion.div
            initial={{ maxWidth: "120px" }}
            animate={{ maxWidth: searchOpen ? "1200px" : "86px" }}
            // Found it made my a bit naseous... so I removed it.
            transition={{ duration: 0.0 }}
            className={classNames("grow ")}
          >
            {searchOpen ? (
              <>
                <DirectoriesTableSearch
                  value={searchValue}
                  setValue={handleSearchValueChange}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                  loading={searchLoading}
                  placeholder="Search"
                />
              </>
            ) : (
              <Button
                styling="ghost"
                onClick={() => {
                  setSearchOpen(true);
                }}
                IconLeft={SearchIcon}
              >
                Search
              </Button>
            )}
          </motion.div>
          {checkedIds.length > 0 ? (
            <div className="flex items-center gap-24">
              <div className="flex items-center gap-12">
                <div className="flex items-center gap-16">
                  <div className="text-12-12 font-medium text-blue-500">{checkedIds.length} selected</div>
                  <Button shade="blue" onClick={() => setCheckedIds([])}>
                    Deselect
                  </Button>
                </div>
                <DirectoriesTableToolbarActions
                  onMove={onMoveAction}
                  // onDelete={onDeleteAction}
                />
              </div>
            </div>
          ) : (
            actionsTray
          )}
        </div>
      </div>
    </div>
  );
};
