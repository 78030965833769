import { Checkbox } from "antd";

interface OwnProps {
  state: "checked" | "unchecked" | "indeterminate";
}

type CheckboxHeaderComponentProps = OwnProps & React.ComponentPropsWithoutRef<typeof Checkbox>;

export const CheckboxHeaderComponent = ({ state, ...props }: CheckboxHeaderComponentProps) => {
  return (
    <span className="h-full w-full">
      <Checkbox
        className="flex h-full w-full items-center justify-center pb-6"
        checked={state === "checked" || state === "indeterminate"}
        indeterminate={state === "indeterminate"}
        {...props}
      />
    </span>
  );
};
