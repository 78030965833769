import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { Directory, DirectoryFile, useDirectoryStructure } from "@/services/directories.service";
import { FilesSortBy, getSortedTags } from "@/services/files.service";
import { FileTypeOrDirectory } from "@/types/app/file";
import Button from "@components/library/Button";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import Tag from "@components/library/v2/Tag";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import Link from "next/link";
import { useMemo } from "react";
import { EmptyState } from "../EmptyState";
import { StackedEntityIcons } from "../Library/StackedEntityIcons";
import { getFilteredItemsFromDirectory } from "./FilesDirectoriesView";

type DirectoryWithChildren = Directory & { files: DirectoryFile[] };

export const FilesGrid = ({
  rowItems,
  filteredEntities,
  setFilteredEntities,
  setSource,
  filteredTags,
  setFilteredTags,
}: {
  rowItems?: { directories: Directory[]; files: DirectoryFile[] };
  filteredEntities: FileTypeOrDirectory[];
  setFilteredEntities: (entities: FileTypeOrDirectory[]) => void;
  filteredTags: string[];
  setSource: (source: string) => void;
  setFilteredTags: (tags: string[]) => void;
}) => {
  const { structure } = useDirectoryStructure({ template: true });

  const directoriesWithChildren = useMemo(() => {
    if (!rowItems || !structure) {
      return [];
    }
    return (
      (rowItems?.directories.map((directory) => {
        return {
          ...directory,
          files: getFilteredItemsFromDirectory(directory, structure, FilesSortBy.created_at, "desc").files,
        };
      }) as DirectoryWithChildren[]) || []
    );
  }, [rowItems, structure]);

  if (!rowItems || (rowItems.files.length === 0 && rowItems.directories.length === 0)) {
    return (
      <FilesGridEmptyState
        clearFilters={
          filteredEntities.length > 0 || filteredTags.length > 0
            ? () => {
                setFilteredEntities([]);
                setFilteredTags([]);
                setSource("");
              }
            : undefined
        }
      />
    );
  }
  return (
    <div className="flex h-full w-full flex-col @container">
      <div className="grid w-full justify-between gap-16 @lg:grid-cols-2 @2xl:grid-cols-3 ">
        {directoriesWithChildren.map((item) => (
          <FileOrDirectoryCard key={item.id} item={item} />
        ))}
        {rowItems.files.map((item) => (
          <FileOrDirectoryCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

const FileOrDirectoryCard = ({ item }: { item: DirectoryFile | DirectoryWithChildren }) => {
  const { slug } = useActiveOrganization();
  const isDirectory = item.type === "directory";

  const cardDescription = item.description;
  const sortedTags = getSortedTags(item.tags);

  return (
    <Link
      className="hover:shadow-box-shadow-sm h-[220px] cursor-pointer rounded-lg border border-stroke-base-3 bg-white p-4 px-16 py-20"
      href={`/${slug}/library/${item.id}`}
    >
      <div className="flex h-full flex-col">
        {isDirectory ? (
          <StackedEntityIcons entityList={item.files.map((file) => file.type)} size={20} />
        ) : (
          <EntityIcon type={item.type} size={20} />
        )}
        <div className="mt-16 flex-grow">
          <div className="text-base-1 min-w-0 shrink truncate text-14-20 font-bold">{item.name}</div>
          {cardDescription && <p className="prose mt-8 line-clamp-3 text-13-20 text-text-base-3">{cardDescription}</p>}
        </div>

        <div className="flex flex-wrap gap-x-6 gap-y-4">
          {sortedTags.map((tag) => (
            <Tag size={18} key={tag} shade="blank">
              {tag}
            </Tag>
          ))}
        </div>
      </div>
    </Link>
  );
};

const FilesGridEmptyState = ({ clearFilters }: { clearFilters?: () => void }) => {
  return (
    <div className="flex h-[360px] min-h-0 w-full grow items-center justify-center text-13-14 font-light text-text-base-3">
      <EmptyState
        header="No results"
        body={"No templates found"}
        Icon={SearchIcon}
        border={false}
        buttons={
          clearFilters && (
            <Button styling="outline" shade="gray" size={32} onClick={clearFilters}>
              Clear filters
            </Button>
          )
        }
      />
    </div>
  );
};
