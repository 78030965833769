import { classNames } from "@/lib/utils";
import Button from "@components/library/Button";
import { LoadingIcon } from "@components/library/Icons";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useRef } from "react";

interface Props {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  loading?: boolean;
  searchOpen: boolean;
  setSearchOpen: (active: boolean) => void;
}

export const DirectoriesTableSearch = ({ searchOpen, setSearchOpen, value, setValue, placeholder, loading }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      if (value) {
        setValue("");
      } else {
        inputRef.current?.blur();
      }
    }
  };

  useEffect(() => {
    if (value) {
      setSearchOpen(true);
    }
  }, [setSearchOpen, value]);

  return (
    <div className="flex w-full grow items-center gap-12">
      <div
        className={classNames(
          "group relative flex grow items-center rounded text-gray-700 outline-none transition-all focus:outline-none",
        )}
      >
        <SearchIcon className="absolute left-12 h-[18px] w-[18px] opacity-60 group-focus-within:opacity-100" />
        <input
          ref={inputRef}
          className="h-32 w-full rounded bg-background-base-1 px-12 pl-52 text-14-20 text-gray-600 placeholder-gray-500 focus:bg-background-base-2 focus:text-oldgray-900 focus:outline-none"
          placeholder={placeholder}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          onBlur={() => (value ? null : setSearchOpen(false))}
          value={value}
          autoFocus
        />
        {loading && <LoadingIcon className="absolute right-12 h-20 w-20 opacity-60" />}
      </div>
      <Button
        styling="ghost"
        shade="blue"
        onClick={() => {
          setSearchOpen(false);
          setValue("");
        }}
        IconLeft={XIcon}
      >
        Cancel
      </Button>
    </div>
  );
};
