import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import { TableColumnStateContextProvider } from "@/context/TableColumnStateContext";
import { DIRECTORY_ID_PREFIX, FILE_ID_PREFIXES, ROOT_DIRECTORY_NAME } from "@/lib/constants";
import { HUGGING_FACE_TAG, useFileSources, useFileTypeFilters, useTagFilters } from "@/lib/hooks/useTagFilters";
import { useAuth } from "@/lib/use-auth";
import usePrevious from "@/lib/use-previous";
import {
  Directory,
  DirectoryFile,
  DirectoryStructure,
  DirectoryStructureChange,
  FileOrDirectory,
  directoryFileFromFile,
  useDirectoryStructure,
} from "@/services/directories.service";
import { FilesSortBy, PRIORITY_TAG, useFiles } from "@/services/files.service";
import { File } from "@/types/app/file";
import Button from "@components/library/Button";
import { EntityIconType } from "@components/library/Entities/EntityIcon";
import { PageSectionHeader } from "@components/library/PageSectionHeader";
import { NoFiles } from "@components/llama/NoFiles";
import CustomHead from "@components/templates/CustomHead";
import { PlusIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import useLocalStorageState from "use-local-storage-state";
import { LibraryBanner } from "../Library/LibraryBanner";
import FilesTable from "../Tables/Files/FilesTable";
import { TableLoadingOverlay } from "../Tables/Files/TableLoadingOverlay";
import { DirectoriesTableToolbar } from "./DirectoriesTableToolbar";
import { FileRenameModal } from "./FileRenameModal";
import { FilesGrid } from "./FilesGrid";
import { FilesGridLoading } from "./FilesGridLoading";
import { FilesSidebar } from "./FilesSidebar";
import { ProjectGetStartedBanner } from "./ProjectGetStartedBanner";
import { WelcomeNewUserModal } from "./WelcomeNewUserModal";

const FilesDirectoriesView = ({ viewType = "home" }: { viewType?: "library" | "home" }) => {
  const { organization, slug } = useActiveOrganization();

  const [projectRenameModalProps, setProjectRenameModalProps] = useState<{ file: DirectoryFile } | null>(null);

  const [search, setSearch] = useState<string>(""); // The value in the search box
  const [debouncedSearch, { isPending }] = useDebounce(search, 200);
  const isLagging = isPending();

  const [sortBy, setSortBy] = useLocalStorageState<FilesSortBy>("files-sort-by", {
    defaultValue: FilesSortBy.tag,
  });
  const [order, setOrder] = useLocalStorageState<"asc" | "desc">("files-order", { defaultValue: "asc" });

  const { filteredEntities, setFilteredEntities } = useFileTypeFilters();
  const { filteredTags, setFilteredTags } = useTagFilters();
  const { source, setSource } = useFileSources();

  const { isLoadingInitialData, total, mutate: mutateFiles } = useFiles({ template: viewType === "library" });

  const router = useRouter();
  const { setFileModalState } = useSidebarContext();

  const directoryId = router.query.id as string | undefined;

  const { structure } = useDirectoryStructure(viewType === "library" ? { template: true } : undefined);

  const directory = structure?.directories.find((d) =>
    // Find directory from directoryId query param or fall back to root directory
    directoryId ? d.id === directoryId : d.parent_id === null,
  );
  const isNonRootDirectory = directory && directory.parent_id !== null;

  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  const previousDirectoryId = usePrevious(directoryId);
  useEffect(() => {
    if (previousDirectoryId !== directoryId) {
      // Reset checked IDs when switching directories
      setCheckedIds([]);
    }
  }, [directoryId, previousDirectoryId]);

  // Debounce the search to limit amount of computation.
  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const hasNoFiles = total === 0 && !isLoadingInitialData;

  // Called when the user clicks the "Move" action button opening the move modal for multiple files.
  const onMoveAction = useCallback(() => {
    if (directory === undefined) {
      console.error("Cannot move as `directory` is undefined");
      return;
    }

    const modalItems: FileOrDirectory[] = checkedIds
      .map((id) => {
        if (FILE_ID_PREFIXES.some((prefix) => id.startsWith(prefix))) {
          const item = structure?.files.find((file) => file.id === id && file.directory_id === directory.id);
          if (item === undefined) {
            console.error("Cannot find File with ID", id);
            return null;
          }
          return { type: "file", item };
        } else if (id.startsWith(DIRECTORY_ID_PREFIX)) {
          const item = structure?.directories.find((directory) => directory.id === id);
          if (item === undefined) {
            console.error("Cannot find Directory with ID", id);
            return null;
          }
          return { type: "directory", item };
        } else {
          console.error("Unhandled ID type", id);
          // Try not to crash
          return null;
        }
      })
      .filter((item) => item !== null) as FileOrDirectory[]; // Type assertion needed as TypeScript doesn't know we've filtered out nulls

    setFileModalState({ open: true, type: "move", files: modalItems });
  }, [checkedIds, directory, setFileModalState, structure?.directories, structure?.files]);

  const previousOrganizationId = usePrevious(organization?.id);
  useEffect(() => {
    if (previousOrganizationId !== organization?.id) {
      mutateFiles();
    }
  }, [mutateFiles, organization?.id, previousOrganizationId]);

  const unfilteredRowItems = useMemo(() => {
    if (directory === undefined || structure === undefined) {
      return undefined;
    }

    return getFilteredItemsFromDirectory(directory, structure, sortBy, order);
  }, [directory, order, sortBy, structure]);

  // In order to get the tag count when a user filters by entity without also fitlering by tag, we need a separate list of items
  const rowItemsFilteredByFileType = useMemo(() => {
    if (directory === undefined || structure === undefined) {
      return undefined;
    }

    return getFilteredItemsFromDirectory(directory, structure, sortBy, order, null, filteredEntities, []);
  }, [directory, order, sortBy, structure, filteredEntities]);

  const rowItems = useMemo(() => {
    if (directory === undefined || structure === undefined) {
      return undefined;
    }

    const output = getFilteredItemsFromDirectory(
      directory,
      structure,
      sortBy,
      order,
      debouncedSearch,
      filteredEntities,
      filteredTags,
      source,
    );
    return output;
  }, [debouncedSearch, directory, order, sortBy, structure, filteredEntities, filteredTags]);

  const allDirectoryFilterTags = unfilteredRowItems?.directories.map((directory) => directory.tags).flat() || [];
  const allFileFilterTags = unfilteredRowItems?.files.map((file) => file.tags).flat() || [];
  const allFilterTags = Array.from(new Set([...allDirectoryFilterTags, ...allFileFilterTags])).sort();
  // if the source is not set, or is humanloop, count it as humanloop
  const countOfHumanloopSourceFiles =
    (rowItemsFilteredByFileType?.files?.filter((file) => !file.tags.includes(HUGGING_FACE_TAG))?.length || 0) +
    (rowItemsFilteredByFileType?.directories?.filter((directory) => !directory.tags.includes(HUGGING_FACE_TAG))
      ?.length || 0);

  const countOfHuggingfaceSourceFiles = rowItemsFilteredByFileType?.files.filter((file) =>
    file.tags.includes(HUGGING_FACE_TAG),
  ).length;

  const allDirectoryTagsForFileType =
    rowItemsFilteredByFileType?.directories.map((directory) => directory.tags).flat() || [];
  const allFileTagsForFileType = rowItemsFilteredByFileType?.files.map((file) => file.tags).flat() || [];
  const allTagsForFileType = [...allDirectoryTagsForFileType, ...allFileTagsForFileType].sort();

  const openRenameDirectoryModal = useCallback(
    (directory: Directory) => {
      setFileModalState({ open: true, type: "rename-directory", directory });
    },
    [setFileModalState],
  );
  const openNewFileModal = useCallback(() => {
    setFileModalState({ open: true, type: "create-file", targetDirectory: directory });
  }, [directory, setFileModalState]);
  const openRenameFileModal = useCallback((file: DirectoryFile) => {
    setProjectRenameModalProps({ file });
  }, []);
  const projectRenameModalOnClose = useCallback(() => {
    setProjectRenameModalProps(null);
  }, []);

  const { onChange: directoryStructureOnChange } = useDirectoryStructure();

  // Handles changes to the directory with optimistic updates.
  const onChange = useCallback(
    (changes?: DirectoryStructureChange[]) => {
      directoryStructureOnChange(changes);

      // TODO: Optimistic updates for these mutates
      mutateFiles();
    },
    [directoryStructureOnChange, mutateFiles],
  );

  // Called after a rename is done to update the current state.
  const onRenameProject = useCallback(
    (file: File) => {
      onChange([
        {
          type: "update",
          item: { type: "file", item: directoryFileFromFile(file) },
        },
      ]);
    },
    [onChange],
  );

  const { user } = useAuth();

  return (
    <>
      {viewType !== "library" && (
        <PageSectionHeader
          title={
            isNonRootDirectory
              ? directory.name
              : user && user.full_name !== null
                ? `Welcome, ${user?.full_name}`
                : "Welcome"
          }
          actionsTray={<></>}
        />
      )}

      <main className="flex flex-col gap-24">
        {viewType === "library" ? (
          <LibraryBanner />
        ) : (
          <>
            <ProjectGetStartedBanner />
          </>
        )}

        {isLoadingInitialData ? (
          viewType === "library" ? (
            <FilesGridLoading />
          ) : (
            <div className="pt-48">
              <TableLoadingOverlay relativePosition />
            </div>
          )
        ) : hasNoFiles && viewType !== "library" ? (
          <NoFiles onNewFileClick={() => openNewFileModal()} />
        ) : (
          <TableColumnStateContextProvider storageKey={`directories-table-${slug}`}>
            <div className="flex w-full gap-48">
              {viewType === "library" && (
                <FilesSidebar
                  allTags={allFilterTags}
                  allTagsForFileType={allTagsForFileType}
                  filteredEntities={filteredEntities}
                  setFilteredEntities={setFilteredEntities}
                  filteredTags={filteredTags}
                  setFilteredTags={setFilteredTags}
                  source={source}
                  setSource={setSource}
                  countOfHumanloopSourceFiles={countOfHumanloopSourceFiles || 0}
                  countOfHuggingfaceSourceFiles={countOfHuggingfaceSourceFiles || 0}
                ></FilesSidebar>
              )}
              <div className="flex w-full flex-col">
                {viewType === "home" && (
                  <DirectoriesTableToolbar
                    directory={directory}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    onMoveAction={onMoveAction}
                    actionsTray={
                      viewType === "home" && (
                        <div className="flex gap-4">
                          <Button elevated IconLeft={PlusIcon} onClick={openNewFileModal}>
                            New
                          </Button>

                          {/* TODO - Uncomment when expanding Library and home UI
                  Not allowing the user to toggle views for now. Introduces more complexity on gating and handling move/create/delete actions.
                  <Button elevated onClick={() => setFilesView(filesView === "list" ? "grid" : "list")}>
                    {filesView === "list" ? "Grid" : "List"}
                  </Button> */}
                        </div>
                      )
                    }
                    searchValue={search}
                    handleSearchValueChange={handleSearchChange}
                    searchLoading={isLagging || (isLoadingInitialData && search.length > 0)}
                  />
                )}

                {viewType === "library" ? (
                  <FilesGrid
                    rowItems={rowItems}
                    filteredEntities={filteredEntities}
                    setFilteredEntities={setFilteredEntities}
                    filteredTags={filteredTags}
                    setFilteredTags={setFilteredTags}
                    setSource={setSource}
                  />
                ) : (
                  <FilesTable
                    rowItems={rowItems}
                    onChange={onChange}
                    openRenameDirectoryModal={openRenameDirectoryModal}
                    openRenameFileModal={openRenameFileModal}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    projectDirectoriesNewButton={
                      <Button elevated IconLeft={PlusIcon} onClick={openNewFileModal}>
                        New
                      </Button>
                    }
                    lagging={isLagging}
                    rowItemsAreSearchResults={Boolean(search)}
                  />
                )}
              </div>
            </div>
          </TableColumnStateContextProvider>
        )}

        {projectRenameModalProps && (
          <>
            <FileRenameModal
              open={projectRenameModalProps !== null}
              onClose={projectRenameModalOnClose}
              onRename={onRenameProject}
              file={projectRenameModalProps.file}
            />
            <ProjectGetStartedBanner />
            {isLoadingInitialData ? (
              <div className="pt-48">
                <TableLoadingOverlay relativePosition />
              </div>
            ) : hasNoFiles ? (
              <NoFiles onNewFileClick={() => openNewFileModal()} />
            ) : (
              <TableColumnStateContextProvider storageKey={`directories-table-${slug}`}>
                <div className="flex flex-col">
                  <DirectoriesTableToolbar
                    directory={directory}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    onMoveAction={onMoveAction}
                    actionsTray={
                      <Button elevated IconLeft={PlusIcon} onClick={openNewFileModal}>
                        New
                      </Button>
                    }
                    searchValue={search}
                    handleSearchValueChange={handleSearchChange}
                    searchLoading={isLagging || (isLoadingInitialData && search.length > 0)}
                  />
                  <FilesTable
                    rowItems={rowItems}
                    onChange={onChange}
                    openRenameDirectoryModal={openRenameDirectoryModal}
                    openRenameFileModal={openRenameFileModal}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    projectDirectoriesNewButton={
                      <Button elevated IconLeft={PlusIcon} onClick={openNewFileModal}>
                        New
                      </Button>
                    }
                    lagging={isLagging}
                    rowItemsAreSearchResults={Boolean(search)}
                  />
                </div>
              </TableColumnStateContextProvider>
            )}
          </>
        )}

        {projectRenameModalProps && (
          <FileRenameModal
            open={projectRenameModalProps !== null}
            onClose={projectRenameModalOnClose}
            onRename={onRenameProject}
            file={projectRenameModalProps.file}
          />
        )}

        <WelcomeNewUserModal />
      </main>
    </>
  );
};

export default FilesDirectoriesView;

export const getFilteredItemsFromDirectory = (
  rootDirectory: Directory,
  structure: DirectoryStructure,
  sortBy: FilesSortBy,
  order: "asc" | "desc",
  filterText?: string | null,
  filteredEntities: EntityIconType[] = [],
  filteredTags: string[] = [],
  source?: string | null,
): {
  directories: Directory[];
  files: DirectoryFile[];
} => {
  const sortComparator = <T extends Directory | DirectoryFile>(a: T, b: T) => {
    let result = 0;
    switch (sortBy) {
      case FilesSortBy.created_at:
        result = a.created_at.valueOf() - b.created_at.valueOf();
        break;
      case FilesSortBy.updated_at:
        result = a.updated_at.valueOf() - b.updated_at.valueOf();
        break;
      case FilesSortBy.name:
        result = a.name.localeCompare(b.name);
        break;
      case FilesSortBy.tag:
        const aHasPriority = a.tags.includes(PRIORITY_TAG);
        const bHasPriority = b.tags.includes(PRIORITY_TAG);

        if (aHasPriority !== bHasPriority) {
          return aHasPriority ? -1 : 1;
        }
        // Sort by joined tags, falling back to name if tags are equal
        const aTags = [...a.tags].sort().join(",");
        const bTags = [...b.tags].sort().join(",");
        result = aTags.localeCompare(bTags) || a.name.localeCompare(b.name);
        break;
    }
    return order === "asc" ? result : -result;
  };

  let filteredDirectories: Directory[] = [];
  let filteredFiles: DirectoryFile[] = [];

  const shouldFilter = !!filterText || !!filteredEntities?.length || !!filteredTags?.length || !!source;

  const filterByText = (name: string) => {
    return !filterText || name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
  };

  const filterBySource = (tags: string[]) => {
    // If no source filter is selected, show all files
    if (!source) return true;

    // If source is humanloop, show files that are either:
    // - missing a source (assumed to be humanloop)
    // - have a source that isn't huggingface
    if (source === "humanloop") {
      return !tags.includes(HUGGING_FACE_TAG);
    }

    // For huggingface, only show files with that specific source
    if (source === "huggingface") {
      return tags.includes(HUGGING_FACE_TAG);
    }

    return false;
  };

  const filterByEntities = (entity: EntityIconType) => {
    return filteredEntities.length === 0 || filteredEntities.includes(entity);
  };

  const filterByTags = (tags: string[]) => {
    return filteredTags.length === 0 || filteredTags.every((tag) => tags.includes(tag));
  };

  if (shouldFilter) {
    const allDirectories: Directory[] = [];
    const allFiles: DirectoryFile[] = [];

    const directoryToDirectoryFiles = structure.files.reduce(
      (acc, file) => {
        if (!acc[file.directory_id]) {
          acc[file.directory_id] = [];
        }
        acc[file.directory_id].push(file);
        return acc;
      },
      {} as Record<string, DirectoryFile[]>,
    );

    const collectItemsFromDirectory = (currentDirectory: Directory) => {
      const childDirectories = structure.directories.filter((directory) => directory.parent_id === currentDirectory.id);
      const directoryFiles = structure.files.filter((file) => file.directory_id === currentDirectory.id);

      allDirectories.push(...childDirectories);
      allFiles.push(...directoryFiles);

      childDirectories.forEach(collectItemsFromDirectory);
    };

    collectItemsFromDirectory(rootDirectory);

    filteredDirectories = allDirectories
      .filter((directory) => filterByText(directory.name))
      .filter((directory) => {
        const directoryFiles = directoryToDirectoryFiles[directory.id] || [];
        return directoryFiles.some((file) => filterByEntities(file.type));
      })
      .filter((directory) => filterByTags(directory.tags) && directory.parent_id === rootDirectory.id)
      .filter((directory) => filterBySource(directory.tags))
      .sort(sortComparator);

    filteredFiles = allFiles
      .filter((file) => filterByText(file.name))
      .filter((file) => filterByEntities(file.type) && file.directory_id === rootDirectory.id)
      .filter((file) => filterByTags(file.tags) && file.directory_id === rootDirectory.id)
      .filter((file) => filterBySource(file.tags))
      .sort(sortComparator);
  } else {
    // No filters apply for text, entities or tags, so directly get the items from the directory
    filteredDirectories = structure.directories
      .filter((directory) => directory.parent_id === rootDirectory.id)
      .sort(sortComparator);

    filteredFiles = structure.files.filter((file) => file.directory_id === rootDirectory.id).sort(sortComparator);
  }

  return {
    directories: filteredDirectories,
    files: filteredFiles,
  };
};
