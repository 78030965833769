import { AgGridReact } from "ag-grid-react";
import { useCallback, useState } from "react";
import { HasId } from "./useTableCheckableRow";
import { CellMouseOverEvent } from "ag-grid-community";

/* Custom hook to handle knowing whether the user has moused over a row.

REMEMBER TO ATTACH onCellMouseOver TO THE GRID'S onCellMouseOver EVENT HANDLER
*/
export const useTableMousedOverRow = <T extends HasId>(gridRef: React.MutableRefObject<AgGridReact<T> | null>) => {
  const [mousedRowId, setMousedRowId] = useState<string | null>(null);
  const onCellMouseOver = useCallback(
    (event: CellMouseOverEvent<T>) => {
      const rowIndex = event.rowIndex;
      if (rowIndex !== null) {
        const rowNode = gridRef.current?.api.getDisplayedRowAtIndex(rowIndex);
        const mousedRowId = rowNode?.id ?? rowNode?.data?.id ?? null;
        setMousedRowId(mousedRowId);
      } else {
        setMousedRowId(null);
      }
    },
    [gridRef],
  );
  return { mousedRowId, setMousedRowId, onCellMouseOver };
};
