import { useAuth } from "@/lib/use-auth";
import { updateUser } from "@/services/users.service";
import { useEffect, useState } from "react";
import { OnboardingPromptEvaluationSnippet } from "../Snippets/OnboardingPromptEvaluationSnippet";
import { mutateDirectoryStructure, useDirectoryStructure } from "@/services/directories.service";
import { upsertPrompt } from "@/services/prompts.service";
import { ModelEndpoint, ModelProvider } from "@/services/playground.service";
import { useRouter } from "next/router";
import { getFileHref } from "@/lib/path-utils";
import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import * as Dialog from "@radix-ui/react-dialog";
import Button from "@components/library/Button";
import { ArrowRight } from "@phosphor-icons/react";
import { analytics } from "@/lib/analytics/analytics.client";
import { LoadingIcon } from "@components/library/Icons";
import { hlToastApiError } from "@components/library/Toast";

/** Modal shown to new user with steps to populate a Prompt and Evaluation.
 *
 * This is not suitable for new users who are invited to an existing Org that has files,
 * and so we do not show this modal in that case.
 */
export const WelcomeNewUserModal = () => {
  const { user, mutate } = useAuth();

  // Always initially set to false, so there is no flickering on->off as data arrives.
  const [openWelcomeNewUserModal, setOpenWelcomeNewUserModal] = useState(false);
  const { structure } = useDirectoryStructure();

  useEffect(() => {
    if (user && !user.ui_state?.hasDismissedWelcomeModal && structure?.files) {
      // Check for files. As we have initial Evaluators, we have to ignore those.
      // Here we only check for Prompts.
      const hasPrompts = structure?.files && structure.files.filter((f) => f.type === "prompt").length > 0;
      if (!hasPrompts) {
        setOpenWelcomeNewUserModal(true);
      }
    }
  }, [structure?.files, user]);

  const onClose = async () => {
    setOpenWelcomeNewUserModal(false);
    const updatedUserUIState = { ...user?.ui_state, hasDismissedWelcomeModal: true };
    await updateUser({ ui_state: updatedUserUIState });
    mutate();
  };

  const [creatingExamplePrompt, setCreatingExamplePrompt] = useState(false);
  const router = useRouter();
  const { slug } = useActiveOrganization();

  const onSkip = async () => {
    analytics.track("Button Clicked", {
      purpose: "skip-to-workspace",
      location: "onboarding",
    });
    setCreatingExamplePrompt(true);
    try {
      const promptResponse = await upsertPrompt({
        path: "Example Prompt",
        commit_message: "Initial commit",
        model: "gpt-4o-mini",
        endpoint: ModelEndpoint.chat,
        template: [
          {
            role: "system",
            content: "You are a friendly assistant. Greet people with their name. You are talking to {{name}}.",
          },
        ],
        provider: ModelProvider.openai,
        temperature: 0.7,
        max_tokens: -1,

        // Note that these 3 need to be provided, as otherwise the Prompt version,
        // when loaded in Editor and used to create new generations, will be saved
        // as a new Prompt version.
        // Specifically, our backend has a type hint on these fields as `float`,
        // but a default value of int (0 or 1 depending on the field). This means
        // that if the values are not provided, the backend will use the int values
        // that will differ from the values if those specific defaults are passed in.
        // (i.e. passing in "1" will yield a `1.0` float, but not passing in any value
        // will yield a `1` int.)
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });
      mutateDirectoryStructure(); // Update sidebar to show the new Prompt.
      onClose();
      router.push(
        `${getFileHref({
          id: promptResponse.data.id,
          page: "editor",
          slug,
        })}/${promptResponse.data.version_id}`,
      );
    } catch (error) {
      hlToastApiError({ error, titleFallback: "Failed to create Example Prompt" });
    } finally {
      setCreatingExamplePrompt(false);
    }
  };

  return (
    <Dialog.Root open={openWelcomeNewUserModal}>
      <Dialog.Portal>
        <Dialog.Content
          className="fixed inset-0 flex overflow-auto bg-white"
          onOpenAutoFocus={(event) => {
            // Prevent the default behavior of focusing the first focusable element
            // to avoid making it too easy to skip the dialog by pressing "space" or "enter".
            event.preventDefault();
          }}
        >
          <div className="hidden w-[40%] bg-[#f8f7f6] lg:flex">
            {/* Grid lines */}
            <div className="absolute inset-y-56 left-56 flex flex-col gap-8">
              <div className="h-8 w-8 border-b border-r border-black" />
              <div className="flex w-8 grow justify-end">
                <div
                  className="w-1 bg-gradient-to-b"
                  style={{
                    backgroundImage: "linear-gradient(to bottom, #07120C00 0%, #07120CFF 100%)",
                  }}
                />
              </div>
              <div className="h-8 w-8 border-r border-t border-black" />
            </div>

            <div className="flex h-full flex-col py-64 pl-96 pr-56 xl:pr-96">
              <div className="text-16-16 pt-[21px] font-mono text-text-base-2">Get started</div>
              <div className="mt-[39px] grow text-balance font-display text-[48px] leading-none text-text-base-1">
                Let&apos;s run your first Evaluation in code.
              </div>
              <div>
                <Button
                  onClick={onSkip}
                  IconRight={creatingExamplePrompt ? LoadingIcon : ArrowRight}
                  disabled={creatingExamplePrompt}
                >
                  Skip to workspace
                </Button>
              </div>
            </div>
          </div>
          <div className="flex min-h-0 grow justify-center overflow-auto">
            <div className="flex min-w-0 max-w-prose grow flex-col gap-40">
              <div className="flex flex-col gap-24 p-36 md:pb-36 lg:pb-64 lg:pt-[240px]">
                <div className="my-36 flex flex-col gap-24 lg:hidden">
                  <div className="text-balance font-display text-[48px] leading-none text-text-base-1">
                    Let&apos;s run your first Evaluation in code
                  </div>

                  <div>
                    <Button
                      onClick={onSkip}
                      IconRight={creatingExamplePrompt ? LoadingIcon : ArrowRight}
                      disabled={creatingExamplePrompt}
                    >
                      Skip to workspace
                    </Button>
                  </div>
                </div>
                <OnboardingPromptEvaluationSnippet onClose={onClose} />
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
