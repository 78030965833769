import Button from "@components/library/Button";
import { Lissajous1 } from "@components/library/Lissajous";
import { PlusIcon } from "@heroicons/react/outline";
import { EmptyState } from "./EmptyState";

export const NoFiles = ({ onNewFileClick }: { onNewFileClick: () => void }) => {
  return (
    <EmptyState
      className="debug w-full"
      header="Create your first File"
      Icon={Lissajous1}
      // TODO: Change this copy as we expand out files.
      body={<p>Create Prompts for your LLM calls and organize them with Directories</p>}
      buttons={
        <div className="flex gap-24">
          <Button styling="solid" shade={"black"} IconLeft={PlusIcon} size={32} onClick={onNewFileClick}>
            New File
          </Button>
        </div>
      }
    />
  );
};
