import { ColDef } from "ag-grid-community";
import CheckboxCellRenderer, { CheckboxCellRendererParams } from "./CheckboxCellRenderer";
import { CHECKBOX_COL_ID } from "../TableSettingsMenu";
import { CheckboxHeaderComponent } from "./CheckboxHeaderComponent";

interface OwnProps {
  cellRendererParams: CheckboxCellRendererParams;
  checkboxHeaderParams?: { state: "checked" | "unchecked" | "indeterminate"; onChange: () => void };
}
type Props = OwnProps & Omit<Partial<ColDef>, "cellRendererParams">;

// Why a factory? Why not just a component?
// Copilot: "Because we need to pass in the checkboxHeaderParams, which is not a prop of the CheckboxCellRenderer."
// actual answer from @harry: because this returns a coldef, not a react component.
export const checkboxColumnFactory = ({ checkboxHeaderParams, ...props }: Props): ColDef => {
  const colDef: ColDef = {
    colId: CHECKBOX_COL_ID,
    sortable: false,
    resizable: false,
    maxWidth: props.maxWidth ?? 48,
    suppressMovable: true,
    cellClass: "!px-0",
    cellRenderer: CheckboxCellRenderer,
    filter: false,
    ...props,
  };
  if (checkboxHeaderParams) {
    colDef.headerComponent = CheckboxHeaderComponent;
    colDef.headerComponentParams = checkboxHeaderParams;
    colDef.headerClass = "!px-0";
  }
  return colDef;
};
