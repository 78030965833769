import { AgGridReact } from "ag-grid-react";
import { uniq } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";

export interface HasId {
  id: string;
}

/* Custom hook to handle selecting and navigating between rows in a table */
export const useTableCheckableRow = <T extends HasId>(
  gridRef: React.MutableRefObject<AgGridReact<T> | null>,
  checkedIds: string[],
  setCheckedIds: React.Dispatch<React.SetStateAction<string[]>>,
  mousedRowId: string | null,
  drawerOpen?: boolean,
  selectedIsChecked: boolean = false, // If false, use selectedNodes to inform rows to be toggled when 'x' is pressed. If true, treat selectedNodes as the checked rows (and do not reference them when 'x' is pressed).
) => {
  // Check/uncheck the selected row on 'x' keypress (which copies Linear's behaviour)
  useHotkeys(
    "x",
    (event) => {
      event.preventDefault();
      if (!gridRef.current) {
        return;
      }
      // This is trying to emulate Linear's behaviour on multi-select (not exactly as some things are high effort)
      // If the mouse was the last input used to select a row, then we want to toggle the row that the mouse is over
      // but if multiple rows are selected (intentional), we toggle those
      // otherwise, we toggle the selected row.

      // Note the uniq() feels non-ideal, but it was added because on tables with multiple row selection/mouse selection,
      //  it was possible to get duplicate ids in the checkedIds array. I think we longer term, we may do better with
      //  using ag-grid's row selection logic as 'checked' and seperately handle the 'active' row (the one in the drawer)
      // and the 'moused' row (the one that can be toggled with 'x' when the mouse is over it).

      const selectedRows = gridRef.current.api.getSelectedNodes();
      if (!selectedIsChecked) {
        if (selectedRows.length > 1) {
          const rowIds = selectedRows.map((row) => row.data!.id);
          console.log("Checking multiple rows", rowIds);
          // If all are selected, uncheck all, otherwise check all
          setCheckedIds((prevCheckedIds) => {
            const allSelected = rowIds.every((id) => prevCheckedIds.includes(id));
            return uniq(
              allSelected ? prevCheckedIds.filter((id) => !rowIds.includes(id)) : [...prevCheckedIds, ...rowIds],
            );
          });
          return;
        }
      }

      if (mousedRowId) {
        console.log("Checking moused row", mousedRowId);
        setCheckedIds((prevCheckedIds) => {
          const checked = prevCheckedIds.includes(mousedRowId);
          return uniq(checked ? prevCheckedIds.filter((id) => id !== mousedRowId) : [...prevCheckedIds, mousedRowId]);
        });
        return;
      }

      if (!selectedIsChecked) {
        if (selectedRows.length === 1) {
          const selectedRowId = selectedRows[0].data!.id;
          console.log("Checking selected row", selectedRowId);
          setCheckedIds((prevCheckedIds) => {
            const checked = prevCheckedIds.includes(selectedRowId);
            return uniq(
              checked ? prevCheckedIds.filter((id) => id !== selectedRowId) : [...prevCheckedIds, selectedRowId],
            );
          });
        }
      }
    },
    // Want to enable it so it works while focused on the checkbox
    // input field, but that causes 'x' to not work on the model config name input.
    { enabled: true, enableOnFormTags: [] },
    [checkedIds, mousedRowId],
  );

  useHotkeys(
    "Escape",
    (event) => {
      event.preventDefault();

      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.deselectAll();
      setCheckedIds([]);
    },
    // If the drawer is open, let that take precedence
    { enabled: !drawerOpen, enableOnFormTags: [] },
    [],
  );

  return [checkedIds, setCheckedIds];
};
