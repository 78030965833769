import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { useSidebarContext } from "@/context/SidebarContextProvider";
import { useTableColumnState } from "@/context/TableColumnStateContext";
import useConfirm from "@/context/useConfirm";
import { getDefaultFilePage, getDirectoryHref, getFileHref } from "@/lib/path-utils";
import usePrevious from "@/lib/use-previous";
import { useTableCheckableRow } from "@/lib/useTableCheckableRow";
import { useTableMousedOverRow } from "@/lib/useTableMousedOverRow";
import { clickOnAntdCheckbox } from "@/lib/useTableSelectedRow";
import {
  Directory,
  DirectoryFile,
  DirectoryStructureChange,
  FileOrDirectory,
  deleteDirectory,
  moveItemsAndHandleStructureUpdates,
} from "@/services/directories.service";
import { capitalizedFileType, deleteFile } from "@/services/files.service";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import { getFileTypeFromId } from "@components/library/utils/versions";
import { DATA_DIRECTORY_ID, DATA_DIRECTORY_NAME } from "@components/llama/Projects/DirectoriesBreadcrumbs";
import { ArrowRightIcon, FolderIcon, PencilIcon, TrashIcon } from "@heroicons/react/outline";
import {
  ColDef,
  ICellRendererParams,
  IRowDragItem,
  IRowNode,
  RowDragEvent,
  RowDropZoneParams,
  RowEvent,
  ValueGetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import dayjs, { Dayjs } from "dayjs";
import { classNames, dateComparator, formatNumberSI, pluralise } from "lib/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { actionsColumnFactory } from "../ActionsColumn/ActionsColumn";
import styles from "../AgGrid.module.css";
import { checkboxColumnFactory } from "../CheckboxColumn/checkboxColumnFactory";
import { TableSettingsMenu } from "../TableSettingsMenu";
import filesTableStyles from "./FilesTable.module.css";
import { TableLoadingOverlay } from "./TableLoadingOverlay";
import { TableNoRowsOverlay } from "./TableNoRowsOverlay";
import { RelativeTimestamp } from "@components/atoms/Timestamp";

const DEFAULT_COLUMN_DEF = {
  resizable: false,
  initialFlex: 1, // Setting "flex" instead of "initialFlex" makes columns autosize whenever they're updated.
  sortable: false,
  filter: false,
  minWidth: 160,
};

export type FilesystemRow = { id: string; name: string; createdAt: Dayjs; updatedAt: Dayjs } & FileOrDirectory;

interface Props {
  rowItems?: { directories: Directory[]; files: DirectoryFile[] };
  onChange: (optimisticChanges?: DirectoryStructureChange[]) => void;
  openRenameDirectoryModal: (directory: Directory) => void;
  openRenameFileModal: (file: DirectoryFile) => void;
  checkedIds: string[];
  setCheckedIds: React.Dispatch<React.SetStateAction<string[]>>;
  projectDirectoriesNewButton: JSX.Element;
  lagging: boolean;
  rowItemsAreSearchResults: boolean;
}

const FilesTable = ({
  rowItems,
  onChange,
  openRenameDirectoryModal,
  openRenameFileModal,
  checkedIds,
  setCheckedIds,
  projectDirectoriesNewButton,
  lagging,
  rowItemsAreSearchResults,
}: Props) => {
  const gridRef = useRef<AgGridReact<FilesystemRow> | null>(null);

  const { mousedRowId, onCellMouseOver } = useTableMousedOverRow(gridRef);
  useTableCheckableRow<FilesystemRow>(gridRef, checkedIds, setCheckedIds, mousedRowId, undefined, true);
  const { onGridReadyPreserveColumnState } = useTableColumnState(gridRef);
  const { setFileModalState } = useSidebarContext();

  const router = useRouter();
  const { slug } = useActiveOrganization();
  const organizationSlug = slug || (router.query.orgSlug as string);

  const confirm = useConfirm();

  const actionsColumn = useMemo(() => {
    return {
      ...actionsColumnFactory<FilesystemRow>(
        [
          {
            menuItemProps: ({ data }) => ({
              children: <div className="min-w-[90px] text-left">Open</div>,
              IconLeft: ArrowRightIcon,
              onClick: () => router.push(getHref(data, organizationSlug)),
            }),
          },
          {
            menuItemProps: ({ data }) => ({
              children: "Rename",
              IconLeft: PencilIcon,
              onClick: () => {
                if (data.type === "file") {
                  openRenameFileModal(data.item);
                } else if (data.type === "directory") {
                  openRenameDirectoryModal(data.item);
                } else {
                  // @ts-expect-error Should handle all data.types above.
                  throw new Error(`Unexpected type: ${data.type}`);
                }
              },
            }),
          },
          {
            menuItemProps: ({ data }) => ({
              children: "Move",
              IconLeft: FolderIcon,
              onClick: () => {
                switch (data.type) {
                  case "directory":
                    return setFileModalState({
                      open: true,
                      type: "move",
                      files: [{ type: "directory", item: data.item }],
                    });
                  case "file":
                    return setFileModalState({
                      open: true,
                      type: "move",
                      files: [{ type: "file", item: data.item }],
                    });
                }
              },
            }),
          },
          { type: "divider" },
          {
            menuItemProps: ({ data }) => ({
              children: "Delete",
              IconLeft: TrashIcon,
              onClick: async () => {
                if (data.type === "file") {
                  // Confirm project deletion
                  const file = data.item;
                  const fileType = capitalizedFileType(file.type);
                  try {
                    await confirm({
                      title: `Are you sure you want to delete '${file.name}'?`,
                      content: (
                        <>
                          {`This will delete this ${fileType} along with its logged data.`}
                          <br />
                          <span className="font-bold">This action cannot be undone.</span>
                        </>
                      ),
                      confirmationText: "Delete",
                      cancellationText: "Cancel",
                      confirmButtonProps: { shade: "red", styling: "solid", IconLeft: TrashIcon },
                    });
                    await deleteFile(file.id);
                    hlToast({ title: `${fileType} '${file.name}' deleted`, variant: ToastVariant.Success });
                    onChange([
                      {
                        type: "delete",
                        item: {
                          type: data.type,
                          item: data.item,
                        },
                      },
                    ]);
                  } catch (e: any) {
                    if (e !== undefined) {
                      hlToastApiError({ error: e, titleFallback: `Failed to delete ${fileType} '${file.name}'` });
                    }
                  }
                } else if (data.type === "directory") {
                  // No need to confirm directory deletion as only empty directories can be deleted.
                  try {
                    await deleteDirectory(data.id);
                    onChange([{ type: "delete", item: { type: data.type, item: data.item } }]);
                    hlToast({ title: `Directory '${data.item.name}' deleted`, variant: ToastVariant.Success });
                  } catch (e) {
                    console.log("Error deleting directory", e);
                    hlToastApiError({ error: e, titleFallback: "Failed to delete directory" });
                  }
                } else {
                  // @ts-expect-error Should handle all data.types above.
                  throw new Error(`Unexpected type: ${data.type}`);
                }
              },
            }),
          },
        ],
        <TableSettingsMenu gridRef={gridRef} />,
        { pinned: undefined },
      ),
    };
  }, [confirm, onChange, openRenameDirectoryModal, openRenameFileModal, organizationSlug, router, setFileModalState]);

  const showCount = (data: FilesystemRow) => {
    switch (data.type) {
      case "directory":
        return "";
      case "file":
        // Don't show log count unless it is a prompt, tool, or evaluator.
        if (!["prompt", "tool", "evaluator"].includes(data.item.type)) {
          return "";
        }
        return `${formatNumberSI(data.item.num_logs || 0)} log${pluralise(data.item.num_logs)}`;
    }
  };

  // Memoed separately from checkbox column to avoid rerendering these columns
  // on selection change.
  const fixedColumns: ColDef<FilesystemRow>[] = useMemo(() => {
    return [
      {
        headerName: "Name",
        field: "name",
        colId: "name",
        initialFlex: 3,
        resizable: true,
        sortable: true,
        cellRenderer: ({ data }: ICellRendererParams<FilesystemRow>) => {
          if (!data) return null;
          return (
            <Link href={getHref(data, organizationSlug)} draggable={false}>
              <div className="flex h-full items-center gap-12">
                <RowIcon data={data} />
                <span className="truncate text-13-20 font-medium">{data.name}</span>
              </div>
            </Link>
          );
        },
      },
      {
        headerName: "Count",
        colId: "count",
        width: 100,
        sortable: true,
        valueGetter: (params: ValueGetterParams<FilesystemRow, any>) => {
          if (!params.data) return null;
          return params.data.type === "file" ? params.data.item?.num_logs || 0 : 0;
        },

        cellRenderer: ({ data }: ICellRendererParams<FilesystemRow>) => {
          if (!data) return null;
          return <span className="text-13-20 text-gray-600">{showCount(data)}</span>;
        },
      },
      {
        headerName: "Created",
        colId: "createdAt",
        field: "createdAt",
        initialHide: true,
        width: 160,
        sortable: true,
        comparator: dateComparator,
        cellRenderer: ({ value }: { value: FilesystemRow["createdAt"] }) => (
          <span className="text-13-20 text-gray-600">
            <RelativeTimestamp timestamp={value} />
          </span>
        ),
      },
      {
        headerName: "Updated",
        field: "updatedAt",
        sortable: true,
        width: 100,
        comparator: dateComparator,
        colId: "updatedAt",
        cellRenderer: ({ value }: { value: FilesystemRow["createdAt"] }) => (
          <span className="text-13-20 text-gray-600">
            <RelativeTimestamp timestamp={value} />
          </span>
        ),
      },
    ];
  }, [organizationSlug]);

  const rowData: FilesystemRow[] | null = useMemo(() => {
    if (rowItems === undefined) {
      return null;
    }
    return [
      ...rowItems.directories.map(
        (directory) =>
          ({
            id: directory.id,
            name: directory.name,
            createdAt: dayjs.utc(directory.created_at).local(),
            updatedAt: dayjs.utc(directory.updated_at).local(),
            type: "directory",
            item: directory,
          }) satisfies FilesystemRow,
      ),
      ...rowItems.files.map(
        (file) =>
          ({
            id: file.id,
            name: file.name,
            createdAt: dayjs.utc(file.created_at).local(),
            updatedAt: dayjs.utc(file.updated_at).local(),
            type: "file",
            item: file,
          }) satisfies FilesystemRow,
      ),
    ];
  }, [rowItems]);

  const checkboxHeaderState = useMemo(() => {
    if (checkedIds.length === 0) {
      return "unchecked";
    }
    if (checkedIds.length === rowData?.length) {
      return "checked";
    }
    return "indeterminate";
  }, [checkedIds, rowData]);
  const checkboxHeaderOnChange = useCallback(() => {
    switch (checkboxHeaderState) {
      case "checked":
        setCheckedIds([]);
        break;
      case "unchecked":
      case "indeterminate":
        setCheckedIds(rowData?.map((row) => row.id) ?? []);
        break;
    }
  }, [checkboxHeaderState, rowData, setCheckedIds]);
  const checkboxColumn = useMemo(
    () =>
      checkboxColumnFactory({
        cellRendererParams: { setCheckedIds, checkedIds },
        maxWidth: 36,
        checkboxHeaderParams: {
          state: checkboxHeaderState,
          onChange: checkboxHeaderOnChange,
        },
      }),
    [checkboxHeaderOnChange, checkboxHeaderState, checkedIds, setCheckedIds],
  );

  const columnDefs: ColDef<FilesystemRow>[] = useMemo(
    () => [checkboxColumn, ...fixedColumns, actionsColumn],
    [actionsColumn, checkboxColumn, fixedColumns],
  );

  const navigateToClickedRow = useCallback(
    ({ data, event }: RowEvent<FilesystemRow>) => {
      if (!data || !event) {
        return;
      }
      if (event.target && clickOnAntdCheckbox(event.target)) {
        // Don't navigate if the click was on checkbox.
        return null;
      }
      router.push(getHref(data, organizationSlug), undefined, {});
    },
    [organizationSlug, router],
  );

  // Display all rows if there are fewer than 100 rows, so that the page scrolls naturally.
  // If more than 100, the scrollbar is within the table itself.
  // This is mainly a performance consideration. It is known that the table
  // looks bad when autoHeight is off and there's a scrollbar, but that's
  // better than a ridiculously tall page.
  const autoHeight = !rowData || rowData.length < 100;

  const previousCheckedIds = usePrevious(checkedIds);

  useEffect(() => {
    const api = gridRef.current?.api;
    if (!api) {
      return;
    }

    const deselectedNodes: IRowNode<FilesystemRow>[] = [];
    const selectedNodes: IRowNode<FilesystemRow>[] = [];
    api.forEachNodeAfterFilter((node) => {
      const data = node.data;
      if (!data) {
        return;
      }
      if (!previousCheckedIds) {
        return;
      }
      if (checkedIds.includes(data.id) && !previousCheckedIds.includes(data.id)) {
        selectedNodes.push(node);
      } else if (previousCheckedIds.includes(data.id) && !checkedIds.includes(data.id)) {
        deselectedNodes.push(node);
      }

      api.setNodesSelected({ nodes: selectedNodes, newValue: true });
      api.setNodesSelected({ nodes: deselectedNodes, newValue: false });
    });
  }, [checkedIds, previousCheckedIds, rowData]);

  // Allow dragging of items into directories
  const moveRows = useCallback(
    async (nodes: IRowNode<FilesystemRow>[], targetDirectoryId: string, targetDirectoryName: string) => {
      const items: FileOrDirectory[] = nodes
        .filter((node) => node.data !== undefined)
        .map((node) => {
          const data = node.data!;
          return { type: data.type, item: data.item } as FileOrDirectory;
        });

      // Do nothing if dropped over one of the dragged item.
      // This is technically possible where all other dragged items will be moved
      // but the item dropped on will not be moved (as it'll error), but this feels
      // like a user error that we should not allow.
      const draggedItemIds = items.map((item) => item.item.id);
      if (draggedItemIds.includes(targetDirectoryId)) {
        console.log(`Dropped on selected item(s). Doing nothing.`, items, targetDirectoryId);
        return;
      }

      moveItemsAndHandleStructureUpdates({
        items,
        targetDirectoryId,
        targetDirectoryName,
        onChange,
        confirm,
        onSuccess: () => {
          // We need to uncheck the ids after move as they no longer should be visible to the user
          setCheckedIds([]);
        },
      });
    },
    [confirm, onChange, setCheckedIds],
  );

  // Handle dropping of rows into the table itself.
  const onRowDragEnd = useCallback(
    async (event: RowDragEvent<FilesystemRow>) => {
      removeDropTargetClassFromAllElements();
      const overDirectory = getOverDirectory(event);
      if (!overDirectory) {
        return;
      }
      moveRows(event.nodes, overDirectory.id, overDirectory.name);
    },
    [moveRows],
  );

  // Add drop zones for breadcrumbs.
  useEffect(() => {
    const api = gridRef.current?.api;
    if (!api) {
      return;
    }
    const targetElements: HTMLElement[] = [...document.querySelectorAll(`[${DATA_DIRECTORY_ID}]`)].filter((element) => {
      return element instanceof HTMLElement;
    }) as HTMLElement[];

    const dropZoneParams: RowDropZoneParams[] = targetElements
      .map((targetElement) => {
        const directoryId = targetElement.getAttribute(DATA_DIRECTORY_ID);
        const directoryName = targetElement.getAttribute(DATA_DIRECTORY_NAME);
        if (directoryId === null || directoryName === null) {
          return null;
        }
        return {
          getContainer: () => targetElement,
          onDragStop: (params) => {
            removeDropTargetClassFromAllElements();
            moveRows(params.nodes, directoryId, directoryName);
          },
          onDragEnter: () => {
            addDropTargetClassToElement(targetElement);
          },
          onDragLeave: () => {
            removeDropTargetClassFromAllElements();
          },
        } satisfies RowDropZoneParams;
      })
      .filter((dropZoneParams) => dropZoneParams !== null) as RowDropZoneParams[];

    if (dropZoneParams.length > 0) {
      console.log("Creating drop zones", dropZoneParams, targetElements);
    }

    dropZoneParams.forEach((params) => {
      api.addRowDropZone(params);
    });
    return () => {
      dropZoneParams.forEach((params) => {
        api.removeRowDropZone(params);
      });
    };
  }, [moveRows, rowData]);

  const onRowDragMove = useCallback((event: RowDragEvent<FilesystemRow>) => {
    removeDropTargetClassFromAllElements();

    const overDirectory = getOverDirectory(event);
    if (!overDirectory) {
      return;
    }
    const overNode = event.overNode;
    if (!overNode) {
      console.log(`Could not find overNode for event`, event);
      return;
    }
    // Set hovered
    const api = gridRef.current?.api;
    if (!api) {
      return;
    }
    // Get row DOM element by "row-id"
    const rowDomElement = document.querySelector(`[row-id="${overNode.id}"]`);
    if (!rowDomElement) {
      console.log("Could not find row DOM element for row", overNode);
      return;
    }
    // Add drop target class to row DOM element
    addDropTargetClassToElement(rowDomElement);
  }, []);

  const onRowDragLeave = useCallback((event: RowDragEvent<FilesystemRow>) => {
    removeDropTargetClassFromAllElements();
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => ({ projectDirectoriesNewButton }), [projectDirectoriesNewButton]);

  return (
    <div
      className={classNames(
        "ag-theme-alpine relative w-full",
        styles["ag-grid"],
        autoHeight
          ? `grow ${filesTableStyles["auto-height-table"]} ${filesTableStyles["overflow"]}`
          : `h-full min-h-[600px] basis-0 ${filesTableStyles["fixed-height-table"]}`,
        lagging ? filesTableStyles["lagging"] : filesTableStyles["not-lagging"],
      )}
    >
      <div className="h-[calc(100vh-280px)]">
        <AgGridReact
          ref={gridRef}
          domLayout={autoHeight ? "autoHeight" : "normal"}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={columnDefs}
          getRowId={getRowId}
          onGridReady={onGridReadyPreserveColumnState}
          rowData={rowData}
          suppressCellFocus
          suppressMovableColumns
          onRowClicked={navigateToClickedRow}
          rowClass="cursor-pointer"
          onCellMouseOver={onCellMouseOver}
          colResizeDefault="shift"
          className={filesTableStyles["table"]}
          rowBuffer={10}
          rowSelection="multiple"
          suppressRowClickSelection
          rowDragEntireRow
          rowDragMultiRow
          onRowDragEnd={onRowDragEnd}
          onRowDragMove={onRowDragMove}
          onRowDragLeave={onRowDragLeave}
          rowDragText={rowDragText}
          loadingOverlayComponent={TableLoadingOverlay}
          noRowsOverlayComponent={rowItemsAreSearchResults ? undefined : TableNoRowsOverlay}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        />
      </div>
    </div>
  );
};

export default FilesTable;

const RowIcon = ({ data }: { data: FilesystemRow }) => {
  return <EntityIcon type={data.type === "directory" ? "directory" : data.item.type} size={20} />;
};

const getRowId = ({ data }: { data: FilesystemRow }) => data.id;

const getHref = (data: FilesystemRow, organizationSlug: string): string => {
  // Would prefer to dynamically generate URL here to keep path/query params.
  // I think we need NextJS's `prepareUrlAs` but it's not exported.
  // See: https://github.com/vercel/next.js/discussions/38961
  switch (data.type) {
    case "directory":
      return getDirectoryHref({ slug: organizationSlug, id: data.id });
    case "file":
      const fileType = getFileTypeFromId(data.id);
      return getFileHref({ slug: organizationSlug, id: data.id, page: getDefaultFilePage(fileType) });
  }
};

/**
 * Get the directory that the dragged item is being dragged over.
 * Returns undefined if the item is not being dragged over a directory.
 */
const getOverDirectory = (event: RowDragEvent<FilesystemRow>): Directory | undefined => {
  const { overNode, overIndex } = event;
  if (!overNode || overIndex === undefined) {
    return;
  }
  const overDatum = overNode.data;
  if (!overDatum) {
    return;
  }
  const overType = overDatum.type;
  if (overType !== "directory") {
    return;
  }
  return overDatum.item;
};

const DROP_TARGET_CLASS = "drop-target";

const removeDropTargetClassFromAllElements = () => {
  const rowDomElements = document.querySelectorAll(`.${DROP_TARGET_CLASS}`);
  rowDomElements.forEach((rowDomElement) => {
    rowDomElement.classList.remove(DROP_TARGET_CLASS);
  });
};

const addDropTargetClassToElement = (element: Element) => {
  element.classList.add(DROP_TARGET_CLASS);
};

/**
 * Override the default "N rows" with "N items"
 */
const rowDragText = (params: IRowDragItem, dragItemCount: number): string => {
  return `${dragItemCount} item${pluralise(dragItemCount)}`;
};
