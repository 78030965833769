import { classNames } from "@/lib/utils";

export const FilesGridLoading = () => {
  return (
    <div className={classNames("flex h-full w-full flex-col gap-8")}>
      <div className="flex h-full w-full gap-48">
        <div className="flex h-[480px] w-[250px] animate-pulse rounded-ms bg-gray-50" />
        <div className="flex h-full w-full flex-col @container">
          <div className="grid w-full justify-between gap-16 @lg:grid-cols-2 @2xl:grid-cols-3 ">
            <LoadingCard delay="delay-25" />
            <LoadingCard delay="delay-50" />
            <LoadingCard delay="delay-75" />
            <LoadingCard delay="delay-25" />
            <LoadingCard delay="delay-50" />
            <LoadingCard delay="delay-75" />
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingCard = ({ delay }: { delay?: string }) => (
  <div className={classNames("h-[240px] shrink-0 animate-pulse rounded-ms bg-gray-50", delay)} />
);
