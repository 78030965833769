import { FILE_TYPES_AND_DIRECTORY, FileTypeOrDirectory, SOURCE_TYPE } from "@/types/app/file";
import { parseAsArrayOf, parseAsString, parseAsStringEnum, useQueryState } from "nuqs";
import { useEffect } from "react";


export const HUGGING_FACE_TAG = "Hugging Face";

/**
 * Hook for managing tag filters in the URL
 */
export function useTagFilters() {
  const [value, setValue] = useQueryState("tags", parseAsArrayOf(parseAsString).withOptions({ history: "push" }));

  // Clear any empty tags so its not in the URL `?tags=&...`
  useEffect(() => {
    if (value?.length === 0) {
      setValue(null);
    }
  }, [value, setValue]);

  return {
    filteredTags: value ?? [],
    setFilteredTags: setValue,
  };
}

/**
 * Hook for managing file type filters in the URL
 */
export function useFileTypeFilters() {
  const [value, setValue] = useQueryState(
    "types",
    parseAsArrayOf(parseAsStringEnum([...FILE_TYPES_AND_DIRECTORY])).withOptions({ history: "push" }),
  );

  useEffect(() => {
    if (value?.length === 0) {
      setValue(null);
    }
  }, [value, setValue]);

  return {
    filteredEntities: value ?? [],
    setFilteredEntities: setValue,
  };
}

export function useFileSources() {
  const [value, setValue] = useQueryState(
    "sources",
    parseAsStringEnum([...SOURCE_TYPE]).withOptions({ history: "push" })
  );

  useEffect(() => {
    if (value === null || value === "") {
      setValue(null);
    }
  }, [value, setValue]);

  return {
    source: value ?? "",
    setSource: setValue,
  };
}
