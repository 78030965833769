import { HUGGING_FACE_TAG } from "@/lib/hooks/useTagFilters";
import { classNames } from "@/lib/utils";
import { FileTypeOrDirectory } from "@/types/app/file";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import React from "react";

export const FilesSidebar = ({
  allTags,
  allTagsForFileType,
  filteredTags,
  setFilteredTags,
  filteredEntities,
  setFilteredEntities,
  source,
  setSource,
  countOfHumanloopSourceFiles,
  countOfHuggingfaceSourceFiles,
}: {
  allTags: string[];
  allTagsForFileType: string[];
  filteredTags: string[];
  setFilteredTags: (tags: string[]) => void;
  filteredEntities: FileTypeOrDirectory[];
  setFilteredEntities: (entities: FileTypeOrDirectory[]) => void;
  source: string;
  setSource: (source: string) => void;
  countOfHumanloopSourceFiles: number;
  countOfHuggingfaceSourceFiles: number;
}) => {
  const filterTagsWithCount = allTagsForFileType.reduce(
    (acc, tag) => {
      if (acc[tag]) {
        acc[tag]++;
      } else {
        acc[tag] = 1;
      }
      return acc;
    },
    {} as Record<string, number>,
  );

  const entityList: { entityType: FileTypeOrDirectory; label: string; description: string; backgroundColor: string }[] =
    [
      {
        entityType: "prompt",
        label: "Prompts",
        description: "Generate text using an LLM",
        backgroundColor: "bg-gray-700",
      },
      {
        entityType: "evaluator",
        label: "Evaluators",
        description: "Monitor and evaluate Logs",
        backgroundColor: "bg-orange-500",
      },
      {
        entityType: "dataset",
        label: "Datasets",
        description: "Collect Datapoints for evals",
        backgroundColor: "bg-blue-500",
      },
      {
        entityType: "tool",
        label: "Tools",
        description: "Enable Prompts to take action",
        backgroundColor: "bg-violet-500",
      },
      {
        entityType: "flow",
        label: "Flows",
        description: "Evaluate multi-step apps",
        backgroundColor: "bg-cyan-500",
      },
    ];

  return (
    <div className="flex min-w-[250px] flex-col gap-24">
      <div className="flex flex-col gap-4">
        <SidebarLabel label="types" />
        <div className="flex flex-col gap-2 px-2">
          {entityList.map((entity, i) => (
            <React.Fragment key={i}>
              <SidebarFileItem
                key={entity.label}
                entityType={entity.entityType}
                label={entity.label}
                description={entity.description}
                backgroundColor={entity.backgroundColor}
                selected={filteredEntities.includes(entity.entityType)}
                setItem={(item) => {
                  setFilteredTags([]);
                  if (filteredEntities.includes(item)) {
                    setFilteredEntities(filteredEntities.filter((entity) => entity !== item));
                  } else {
                    setFilteredEntities([item]);
                  }
                }}
              />
              {i !== entityList.length - 1 && (
                <div className="flex w-full px-12" key={i}>
                  <div className="flex w-full border border-t border-stroke-base-2 opacity-50" />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <SidebarFilters
        // Hugging face tag is shown in the sources section
        allFilterTags={allTags.filter(tag => tag !== HUGGING_FACE_TAG)}
        filterTagsWithCount={filterTagsWithCount}
        selectedTags={filteredTags}
        setTags={setFilteredTags}
      />
      <SidebarSources
        source={source}
        setTags={setFilteredTags}
        setSource={setSource}
        countOfHumanloopSourceFiles={countOfHumanloopSourceFiles}
        countOfHuggingfaceSourceFiles={countOfHuggingfaceSourceFiles}
      />
    </div>
  );
};

const SidebarLabel = ({ label }: { label: string }) => {
  return <div className="font-semibold flex h-28 items-center px-8 text-10-10 uppercase text-text-base-4">{label}</div>;
};

const SidebarFileItem = ({
  entityType,
  label,
  description,
  backgroundColor,
  selected,
  setItem,
}: {
  entityType: FileTypeOrDirectory;
  label: string;
  description: string;
  backgroundColor?: string;
  selected?: boolean;
  setItem?: (item: FileTypeOrDirectory) => void;
}) => {
  return (
    <div
      className={classNames(
        "flex cursor-pointer select-none items-start gap-8 rounded-ms p-12",
        backgroundColor && selected ? `${backgroundColor} bg-opacity-10` : "hover:bg-background-base-3",
      )}
      onClick={() => setItem?.(entityType)}
    >
      <EntityIcon type={entityType} size={16} />
      <div className="flex flex-col gap-6 font-medium text-text-base-2">
        <div className="text-13-14 font-medium text-text-base-2">{label}</div>
        <div className="text-11-12 font-medium text-text-base-2 opacity-60">{description}</div>
      </div>
    </div>
  );
};

const SidebarSources = ({
  source,
  setSource,
  setTags,
  countOfHumanloopSourceFiles,
  countOfHuggingfaceSourceFiles,
}: {
  source: string;
  setSource: (source: string) => void;
  setTags: (tags: string[]) => void;
  countOfHumanloopSourceFiles: number;
  countOfHuggingfaceSourceFiles: number;
}) => {
  const [animationParent] = useAutoAnimate({
    duration: 100,
  });
  return (
    <div className="flex flex-col gap-4">
      <SidebarLabel label="source" />
      <div className="flex flex-col gap-2" ref={animationParent}>
        <SidebarFilterItem
          key={"humanloop"}
          label={"Humanloop"}
          count={countOfHumanloopSourceFiles}
          selected={source === "humanloop"}
          icon={<img src="/assets/companies/Humanloop Mark - black.png" className="h-16 w-16" alt={""} />}
          setItem={() => {
            setTags([]);
            source === "humanloop" ? setSource("") : setSource("humanloop");
          }}
        />
        <SidebarFilterItem
          key={"huggingface"}
          label={"Hugging Face"}
          count={countOfHuggingfaceSourceFiles}
          selected={source === "huggingface"}
          icon={<img src="/assets/companies/hf-logo.svg" className="h-16 w-16" alt={""} />}
          setItem={() => {
            setTags([]);
            source === "huggingface" ? setSource("") : setSource("huggingface");
          }}
        />
      </div>
    </div>
  );
};

const SidebarFilters = ({
  allFilterTags,
  filterTagsWithCount,
  selectedTags,
  setTags,
}: {
  allFilterTags: string[];
  filterTagsWithCount: Record<string, number>;
  selectedTags: string[];
  setTags: (tags: string[]) => void;
}) => {
  const [animationParent] = useAutoAnimate({
    duration: 100,
  });
  return (
    <div className="flex flex-col gap-4">
      <SidebarLabel label="tags" />
      <div className="flex flex-col gap-2" ref={animationParent}>
        {allFilterTags
          .filter((tag) => filterTagsWithCount[tag] > 0)
          .map((tag) => (
            <SidebarFilterItem
              key={tag}
              label={tag}
              count={filterTagsWithCount[tag]}
              selected={selectedTags.includes(tag)}
              setItem={(item) => {
                if (selectedTags.includes(item)) {
                  setTags(selectedTags.filter((tag) => tag !== item));
                } else {
                  setTags([item]);
                }
              }}
            />
          ))}
      </div>
    </div>
  );
};

const SidebarFilterItem = ({
  label,
  count,
  selected,
  setItem,
  icon,
}: {
  label: string;
  count?: number;
  selected?: boolean;
  setItem?: (item: string) => void;
  icon?: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "flex cursor-pointer items-start gap-8 rounded-ms p-12",
        selected ? "bg-background-base-3" : "hover:bg-background-base-3",
      )}
      onClick={() => setItem?.(label)}
    >
      <div className="flex w-full justify-between text-12-14">
        <div className="flex items-center gap-8">
          {icon}
          <div className="max-w-[200px] truncate text-text-base-2">{label}</div>
        </div>
        <div className="text-text-base-3">{count}</div>
      </div>
    </div>
  );
};
